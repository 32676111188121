import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Label,
  FormFeedback,
  CardImg,
  NavItem,
  NavLink,
  Nav,
  TabPane,
  Row,
  Col,
  TabContent
} from 'reactstrap';
import RadioAnswer from './radioAnswer';
import { beautyPipeline, beautyPipelineCeiling, removeChars, splitSentences } from '../../../helpers/hcutils';
import DeselectableRadioAnswer from './deselectableRadioAnswer';
import classNames from 'classnames';

const RadioQuestion = ({
  question,
  image,
  proposedanswers,
  onChange,
  invalid,
  answer,
  selectedExtendedDescription,
  selectedOtherDetail,
  inline,
  isDeselectable
}) => {
  const [radioSelectedValue, setRadioSelectedValue] = useState(null);
  const [activeTab, setActiveTab] = useState('1');

  //Question tab
  const QuestionTab = ({ toggle, activeTab, setActiveTab, ...rest }) => {
    return (
      <NavItem className="cursor-pointer">
        <NavLink
          className={classNames({ active: activeTab === '1' })}
          onClick={() => {
            toggle('1');
          }}
        >
          Question
        </NavLink>
      </NavItem>
    );
  };

  const QuestionContent = () => {
    return (
      <TabPane tabId="1">
        <Row>
          <Col sm="12 mt-4">
            <RenderDefaultRadioAnswer />
          </Col>
        </Row>
      </TabPane>
    );
  };

  //Navigation tab
  const NavigationTab = ({ toggle, activeTab, setActiveTab, ...rest }) => {
    return (
      <NavItem className="cursor-pointer">
        <NavLink
          className={classNames({ active: activeTab === '2' })}
          onClick={() => {
            toggle('2');
          }}
        >
          Navigation
        </NavLink>
      </NavItem>
    );
  };

  const NavigationContent = () => {
    return (
      <TabPane tabId="2">
        <Row>
          <Col sm="12 mt-4">
            <h1>Navigation</h1>
          </Col>
        </Row>
      </TabPane>
    );
  };

  function Tab({ activeTab, setActiveTab }) {
    const toggle = tab => {
      if (activeTab !== tab) setActiveTab(tab);
    };

    return (
      <>
        <Nav tabs>
          <QuestionTab toggle={toggle} activeTab={activeTab} setActiveTab={setActiveTab} />
          <NavigationTab toggle={toggle} activeTab={activeTab} setActiveTab={setActiveTab} />
        </Nav>
        <TabContent activeTab={activeTab}>
          <QuestionContent />
          <NavigationContent />
        </TabContent>
      </>
    );
  }

  function handleRadioClick(value) {
    if (radioSelectedValue === value) {
      setRadioSelectedValue(null); // Deselecciona si ya estaba seleccionado

      onChange({
        selectedValue: null,
        selectedExtendedDescription: '',
        selectedOtherDetail: ''
      });
    } else {
      setRadioSelectedValue(value); // Selecciona nuevo valor
    }
  }

  // Función para manejar el cambio en la selección del radio
  const handleRadioChange = e => {
    const selectedValue = e.target.value;
    const selectedOption = proposedanswers.find(option => option.validAnswerId === parseInt(selectedValue, 10));

    if (selectedOption) {
      onChange({
        selectedValue: selectedValue,
        selectedExtendedDescription: selectedOption.questionExtendedDescription || '',
        selectedOtherDetail: splitSentences(selectedOption.validOtherDetail) || ''
      });
    } else {
      onChange({
        selectedValue: selectedValue,
        selectedExtendedDescription: '',
        selectedOtherDetail: ''
      });
    }
  };

  let formattedQuestion = removeChars(question);
  if (!formattedQuestion.includes('?|')) formattedQuestion = formattedQuestion.replace('?', '?\n\n');
  if (formattedQuestion.includes('|') && formattedQuestion.includes('^')) {
    formattedQuestion = beautyPipelineCeiling(formattedQuestion);
  }
  if (formattedQuestion.includes('|')) {
    formattedQuestion = beautyPipeline(formattedQuestion);
  }

  const RenderRadioAnswer = props => {
    const { proposedanswer } = props;
    if (isDeselectable) {
      return (
        <DeselectableRadioAnswer
          key={proposedanswer.validAnswerId}
          answerid={proposedanswer.validAnswerId || ''} // Asegúrate de que answerid no sea NaN o undefined
          answer={proposedanswer.validAnswerText}
          inline={inline ? true : false}
          onClick={() => handleRadioClick(proposedanswer.validAnswerId)}
          onChange={handleRadioChange}
          value={proposedanswer.validAnswerId || ''} // Verifica que value no sea NaN o undefined
          invalid={invalid}
          checked={radioSelectedValue === proposedanswer.validAnswerId}
          tooltiptext={proposedanswer.validOtherDetail || ''}
        />
      );
    } else {
      return (
        <RadioAnswer
          key={proposedanswer.validAnswerId}
          answerid={proposedanswer.validAnswerId || ''} // Asegúrate de que answerid no sea NaN o undefined
          answer={proposedanswer.validAnswerText}
          inline={inline ? true : false}
          onChange={handleRadioChange}
          value={proposedanswer.validAnswerId || ''} // Verifica que value no sea NaN o undefined
          invalid={invalid}
          checked={proposedanswer.validAnswerId === answer}
          tooltiptext={proposedanswer.validOtherDetail || ''}
        />
      );
    }
  };

  const RenderDefaultRadioAnswer = () => {
    return (
      <FormGroup>
        {selectedExtendedDescription && (
          <>
            <div
              className="common-font"
              style={{ maxHeight: '4.5em', overflowY: 'auto', lineHeight: '1.5em', paddingRight: '10px' }}
            >
              <p className="mb-0">{selectedExtendedDescription}</p>
            </div>
            <hr style={{ margin: '1rem 0' }} />
          </>
        )}
        <Label for="selectOption" style={{ whiteSpace: 'pre-wrap' }} className="common-font">
          {formattedQuestion}
        </Label>
        {image && (
          <figure className="figure d-block mx-auto" style={{ maxWidth: 'max-content' }}>
            <CardImg src={require(`../../../assets/img/questions/${image}.jpg`)} alt="Question image" />
          </figure>
        )}
        <div>
          {proposedanswers.map((proposedanswer, index) => (
            <RenderRadioAnswer key={index} proposedanswer={proposedanswer} />
          ))}
        </div>
        {invalid && <FormFeedback style={{ display: 'contents' }}>Please select an answer!</FormFeedback>}
        {selectedOtherDetail && (
          <>
            <hr style={{ margin: '1rem 0' }} />
            <div
              className="common-font"
              style={{
                maxHeight: '9em',
                overflowY: 'auto',
                lineHeight: '1.5em',
                paddingRight: '10px',
                marginTop: '1rem'
              }}
            >
              {Array.isArray(selectedOtherDetail) ? (
                selectedOtherDetail.map((text, index) => (
                  <p className="mb-0" key={index}>
                    {text}
                  </p>
                ))
              ) : (
                <p className="mb-0">{selectedOtherDetail}</p>
              )}
            </div>
          </>
        )}
      </FormGroup>
    );
  };

  const RenderMain = () => {
    return isDeselectable ? <Tab activeTab={activeTab} setActiveTab={setActiveTab} /> : <RenderDefaultRadioAnswer />;
  };

  return <RenderMain />;
};

export default RadioQuestion;

RadioQuestion.defaultProps = {
  question: 'How many items are?',
  proposedanswers: [],
  inline: false,
  invalid: false,
  isDeselectable: false,
  selectedOtherDetail: ''
};

RadioQuestion.propTypes = {
  question: PropTypes.string,
  proposedanswers: PropTypes.array,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  selectedExtendedDescription: PropTypes.string,
  selectedOtherDetail: PropTypes.string,
  isDeselectable: PropTypes.bool
};
