import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import MultiSelect from '../../../hcare/components/MultiSelect';
import { removeChars } from '../../../helpers/hcutils';
import 'bootstrap/dist/css/bootstrap.min.css';

const DropMultipleQuestion = ({
  question,
  proposedanswers,
  onChange,
  answer,
  selectedExtendedDescription,
  selectedOtherDetail
}) => {
  const [selectedValues, setSelectedValues] = useState(Array.isArray(answer) ? answer : []);

  useEffect(() => {
    if (Array.isArray(answer) && answer.length > 0) {
      setSelectedValues(answer);
    } else if (proposedanswers.length > 0) {
      setSelectedValues([]);
    }
  }, [proposedanswers, answer]);

  const handleChange = selectedOptions => {
    const selectedValues = Array.isArray(selectedOptions) ? selectedOptions.map(option => option.value) : [];

    setSelectedValues(selectedValues);

    const selectedExtendedDescriptions = selectedValues.map(value => {
      const selectedOption = proposedanswers.find(option => option.validAnswerId === parseInt(value, 10));
      return selectedOption ? selectedOption.questionExtendedDescription || '' : '';
    });

    const selectedOtherDetails = selectedValues.map(value => {
      const selectedOption = proposedanswers.find(option => option.validAnswerId === parseInt(value, 10));
      return selectedOption ? selectedOption.validOtherDetail || '' : '';
    });

    onChange({
      selectedValues,
      selectedExtendedDescription: selectedExtendedDescriptions.join(' | '),
      selectedOtherDetail: selectedOtherDetails.join('<br>')
    });
  };

  const formattedQuestion = removeChars(question);

  const options = useMemo(
    () =>
      proposedanswers.map(proposedanswer => ({
        value: proposedanswer.validAnswerId,
        label: proposedanswer.validAnswerText
      })),
    [proposedanswers]
  );

  return (
    <FormGroup>
      {selectedExtendedDescription && (
        <>
          <div style={{ maxHeight: '10em', overflowY: 'auto', lineHeight: '1.5em', paddingRight: '10px' }}>
            <p className="mb-0">{selectedExtendedDescription}</p>
          </div>
          <hr style={{ margin: '1rem 0' }} />
        </>
      )}
      <Label htmlFor="selectOption" style={{ whiteSpace: 'pre-wrap', marginBottom: '1rem', display: 'block' }}>
        {formattedQuestion}
      </Label>
      <MultiSelect
        name="answer"
        id="selectOption"
        options={options}
        isMulti
        closeMenuOnSelect={false}
        classNamePrefix="react-select"
        value={options.filter(option => selectedValues.includes(option.value))}
        onChange={handleChange}
      />
      {selectedOtherDetail && (
        <>
          <hr style={{ margin: '1rem 0' }} />
          <div
            style={{
              maxHeight: '12em',
              overflowY: 'auto',
              lineHeight: '1.5em',
              paddingRight: '10px',
              marginTop: '1rem'
            }}
          >
            <p className="mb-0" dangerouslySetInnerHTML={{ __html: selectedOtherDetail }} />
          </div>
        </>
      )}
    </FormGroup>
  );
};

DropMultipleQuestion.defaultProps = {
  question: 'How many items are?',
  proposedanswers: [],
  answer: [],
  selectedExtendedDescription: '',
  selectedOtherDetail: ''
  
};

DropMultipleQuestion.propTypes = {
  question: PropTypes.string,
  proposedanswers: PropTypes.arrayOf(
    PropTypes.shape({
      validAnswerId: PropTypes.number.isRequired,
      validAnswerText: PropTypes.string.isRequired,
      validOtherDetail: PropTypes.string,
      questionExtendedDescription: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  answer: PropTypes.array,
  selectedExtendedDescription: PropTypes.string,
  selectedOtherDetail: PropTypes.string
};

export default DropMultipleQuestion;
