import React, { useContext, useState, useEffect, useCallback, Fragment } from 'react';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  Spinner,
  FormGroup,
  Label
} from 'reactstrap';

import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loader';

import RadioQuestion from './radioQuestion';
import CheckQuestion from './checkQuestion';
import CheckListQuestion from './checkListQuestion';
import DropQuestion from './dropQuestion';
import DropMultipleQuestion from './dropMultipleQuestion';

import ExamService from '../../../services/exam/exam.service';
import './questions.css';
import { splitSentences } from '../../../helpers/hcutils';


const RADIOQUESTION_TYPE = 'RADIOQUESTION';
const CHECKLISTQUESTION_TYPE = 'CHECKLISTQUESTION';
const CHECKQUESTION_TYPE = 'CHECKQUESTION';
const DROPQUESTION_TYPE = 'DROPQUESTION';
const DROP_MULTIPLE_QUESTION_TYPE = 'DROP_MULTIPLE_QUESTION';
const UNKNOWN_QUESTION_TYPE = 'UNKNOWN_QUESTION';

const ANCHO_PC_VIEW = 500; // Variable global para el ancho mínimo de vista móvil
const IS_MOBILE_VIEW = window.innerWidth < ANCHO_PC_VIEW;

const PageNofM = props => {
  return (
    <p className={props.className}>
      {props.n} of {props.m}
    </p>
  );
};

const CheckListDescription = props => {
  return (
    <Alert className="p-1 mb-1" color="primary">
      <p>Please rate your level of experience:</p>
      <p>
        <strong>
          Frequency and Proficiency scale will assess your experience (within the last year) using the following (check
          the appropriate boxes below when going through the assessment):
        </strong>
      </p>
      <p>Scale:</p>
      <ul>
        <li>
          <strong>N/A- No Experience / No Patient Care</strong>
        </li>
        <li>
          <strong>Level 1 - Limited Experience / Rarely Done</strong>
        </li>
        <li>
          <strong>Level 2 - May Need Some Review (1-2 month)</strong>
        </li>
        <li>
          <strong>Level 3 - Occasionally (2-3 weekly)</strong>
        </li>
        <li>
          <strong>Level 4 - Experience / Frequently Done (daily and weekly)</strong>
        </li>
      </ul>
    </Alert>
  );
};

const ExamDialog = props => {
  const { visible, setVisible, user, exam, backdrop } = props;

  const initialForm = { questionId: 0, answer: null };

  //Confirm dialog
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  // const [showWarning, setShowWarning] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [limits, setLimits] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(1);
  const [lastquestionid, setLastquestionid] = useState(0);

  // contiene la respuesta de la pregunta actual (currentQuestion). currentQuestion puede estar respondida o no.
  const [form, setForm] = useState(initialForm);
  const [invalidQuestion, setInvalidQuestion] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState('');

  const [selectedExtendedDescription, setSelectedExtendedDescription] = useState('');
  const [selectedOtherDetail, setSelectedOtherDetail] = useState('');
  const [inComments, setInComments] = useState('');

  const [isDeselectable, setIsDeselectable] = useState(false);

  // Función para manejar el cambio en las notas
  const handleNotesChange = e => {
    setInComments(e.target.value);
  };

  const fillCurrentAnswer = question => {
    if (isRadioQuestion(question.questionTypeDescription) || isDropQuestion(question.questionTypeDescription)) {
      const answerId = question.clinicianAnswersList[0]?.clinicianAnswerId;

      setForm({
        questionId: question.questionId,
        answer: answerId ? `${answerId}` : ''
      });

      const selectedOption = question.validAnswers.find(option => option.validAnswerId === parseInt(answerId, 10));

      if (selectedOption) {
        setSelectedExtendedDescription(selectedOption.questionExtendedDescription || '');
        setSelectedOtherDetail(selectedOption.validOtherDetail || '');
      } else {
        const firstOption = question.validAnswers[0];
        setSelectedExtendedDescription(firstOption.questionExtendedDescription || '');
        setSelectedOtherDetail('');
      }
      
    } else if (isCheckListQuestion(question.questionTypeDescription)) {
      let answersobj = {};
      question.validAnswers.forEach(function(proposedanswer) {
        answersobj['proficiencyanswerId_' + proposedanswer.validAnswerId] = proposedanswer.proficiency;
        answersobj['frequencyanswerId_' + proposedanswer.validAnswerId] = proposedanswer.frequency;
      });

      setForm({
        questionId: question.questionId,
        answer: answersobj
      });
    } else if (
      isCheckQuestion(question.questionTypeDescription) ||
      isDropMultipleQuestion(question.questionTypeDescription)
    ) {
      let ckeckAnswers = {};
      question.clinicianAnswersList.forEach(function(proposedanswer) {
        ckeckAnswers['answerId' + proposedanswer.clinicianAnswerId] = proposedanswer.clinicianAnswerId;
      });
      setForm({
        questionId: question.questionId,
        answer: ckeckAnswers
      });

      const selectedOption = question.validAnswers.find(option => option.validAnswerId === parseInt(ckeckAnswers, 10));

      if (selectedOption) {
        setSelectedExtendedDescription(selectedOption.questionExtendedDescription || '');
        setSelectedOtherDetail(selectedOption.validOtherDetail || '');
      } else {
        const firstOption = question.validAnswers[0];
        setSelectedExtendedDescription(firstOption.questionExtendedDescription || '');
        setSelectedOtherDetail('');
      }
    }
  };

  const toastErrorMessage = error => {
    const resMessage =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    if (resMessage === 'Index: 0, Size: 0') {
      toast.error('An error has occurred! Please contact customer service!');
      close();
    } else {
      toast.error(`${resMessage}`);
    }
  };

  const isDropQuestion = questionTypeDescription => {
    return questionTypeDescription === 'drop_1_1';
  };

  const isDropMultipleQuestion = questionTypeDescription => {
    return questionTypeDescription === '1_9_*';
  };

  const isRadioQuestion = questionTypeDescription => {
    if (questionTypeDescription) {
      if (questionTypeDescription === 'true_false') {
        return true;
      }

      const patternValue = qPattern(questionTypeDescription);

      if (Number.isNaN(patternValue)) {
        return false;
      }

      if (patternValue === 1 && !questionTypeDescription.toLowerCase().includes('drop')) {
        return true;
      }
    }

    return false;
  };

  const isCheckListQuestion = questionTypeDescription => {
    if (questionTypeDescription) {
      return questionTypeDescription.toLowerCase().includes('checklist');
    }
    return false;
  };

  const isCheckQuestion = questionTypeDescription => {
    if (questionTypeDescription) {
      let patternValue = qPattern(questionTypeDescription);

      if (Number.isNaN(patternValue)) return false;

      if (patternValue > 1) return true;
    }
    return false;
  };

  const questionType = questionTypeDescription => {
    if (isRadioQuestion(questionTypeDescription)) {
      return RADIOQUESTION_TYPE;
    }
    if (isCheckListQuestion(questionTypeDescription)) {
      return CHECKLISTQUESTION_TYPE;
    }
    if (isCheckQuestion(questionTypeDescription)) {
      return CHECKQUESTION_TYPE;
    }
    if (isDropQuestion(questionTypeDescription)) {
      return DROPQUESTION_TYPE;
    }
    if (isDropMultipleQuestion(questionTypeDescription)) {
      return DROP_MULTIPLE_QUESTION_TYPE;
    }
    return UNKNOWN_QUESTION_TYPE;
  };

  const asyncUpdateQuestion = qType => {
    let updateResult;
    const comments = isGuidedExam() ? inComments : null;


    switch (qType) {
      case RADIOQUESTION_TYPE:
      case CHECKQUESTION_TYPE:
      case DROPQUESTION_TYPE:
      case DROP_MULTIPLE_QUESTION_TYPE:
        updateResult = ExamService.updateQuestion(
          exam.examId,
          user.id,
          currentQuestion.questionId,
          getAnswer(form.answer),
          exam.clinicianAssessmentId,
          comments
        );
        break;

      case CHECKLISTQUESTION_TYPE:
        updateResult = ExamService.updateQuestionChecklist(
          exam.examId,
          user.id,
          currentQuestion.questionId,
          getAnswer(form.answer)
        );
        break;

      default:
        break;
    }
    return updateResult;
  };

  const onFirstClick = e => {
    e.preventDefault();

    if (currentQuestion.questionId === limits.firstQuestionId) return;

    let updateQuestionService, nextQuestion, qType;
    qType = questionType(currentQuestion.questionTypeDescription);

    if (currentQuestion.questionId === limits.lastQuestionId) {
      if (invalidQuestion) setInvalidQuestion(false);
      nextQuestion = ExamService.nextQuestion(exam.examId, user.id, limits.firstQuestionId, exam.clinicianAssessmentId);
    } else {
      let isValid = isValidQuestion();
      switch (qType) {
        case RADIOQUESTION_TYPE:
        case CHECKQUESTION_TYPE:
        case DROPQUESTION_TYPE:
        case DROP_MULTIPLE_QUESTION_TYPE:
          if (isValid) {
            updateQuestionService = asyncUpdateQuestion(qType);
          } else {
            setInvalidQuestion(true);
          }
          break;
        case CHECKLISTQUESTION_TYPE:
          // when the value of qType is CHECKLISTQUESTION_TYPE
          //   1. If there is any answer, it is saved.
          //   2. The previous question is taken
          setInvalidQuestion(false);

          if (Array.isArray(isValid) && isValid.some(answer => answer.isValid)) {
            updateQuestionService = asyncUpdateQuestion(qType);
          } else {
            isValid = true;
          }
          break;
        default:
          console.log(`Sorry, unknown question. ${qType}`);
      }
      if (isValid && (qType === RADIOQUESTION_TYPE || qType === CHECKQUESTION_TYPE || qType === DROPQUESTION_TYPE))
        nextQuestion = ExamService.nextQuestion(
          exam.examId,
          user.id,
          limits.firstQuestionId,
          exam.clinicianAssessmentId
        );
      if (isValid && qType === CHECKLISTQUESTION_TYPE)
        nextQuestion = ExamService.nextQuestion(
          exam.examId,
          user.id,
          limits.firstQuestionId,
          exam.clinicianAssessmentId
        );
    }
    if (updateQuestionService) {
      updateQuestionService
        .then(response => {
          nextQuestion
            .then(response => {
              setInvalidQuestion(false);

              if (currentQuestion.questionId === lastquestionid) setLastquestionid(lastquestionid + 1);

              if (response.questionId > lastquestionid) setLastquestionid(response.questionId);

              fillCurrentAnswer(response);

              setCurrentQuestion(response);

              setIsLoaded(true);
            })
            .catch(error => {
              toastErrorMessage(error);
            });
        })
        .catch(error => {
          toastErrorMessage(error);
        });
    } else {
      if (nextQuestion) {
        nextQuestion
          .then(response => {
            setInvalidQuestion(false);

            fillCurrentAnswer(response);

            setCurrentQuestion(response);

            if (response.questionId > lastquestionid) setLastquestionid(response.questionId);

            setIsLoaded(true);
          })
          .catch(error => {
            toastErrorMessage(error);
          });
      }
    }
  };

  const onPreviousClick = e => {
    e.preventDefault();

    if (currentQuestion.questionId === limits.firstQuestionId) return;

    let updateQuestionService, nextQuestion, qType;
    qType = questionType(currentQuestion.questionTypeDescription);

    if (currentQuestion.questionId === limits.lastQuestionId) {
      if (invalidQuestion) setInvalidQuestion(false);
      nextQuestion = ExamService.nextQuestion(
        exam.examId,
        user.id,
        currentQuestion.questionId - 1,
        exam.clinicianAssessmentId
      );
    } else {
      let isValid = isValidQuestion();
      switch (qType) {
        case RADIOQUESTION_TYPE:
        case CHECKQUESTION_TYPE:
        case DROPQUESTION_TYPE:
        case DROP_MULTIPLE_QUESTION_TYPE:
          if (isValid) {
            updateQuestionService = asyncUpdateQuestion(qType);
          } else {
            setInvalidQuestion(true);
          }
          break;
        case CHECKLISTQUESTION_TYPE:
          // when the value of qType is CHECKLISTQUESTION_TYPE
          //   1. If there is any answer, it is saved.
          //   2. The previous question is taken
          setInvalidQuestion(false);

          if (Array.isArray(isValid) && isValid.some(answer => answer.isValid)) {
            updateQuestionService = asyncUpdateQuestion(qType);
          } else {
            isValid = true;
          }
          break;
        default:
          console.log(`Sorry, unknown question. ${qType}`);
      }
      if (isValid && (qType === RADIOQUESTION_TYPE || qType === CHECKQUESTION_TYPE || qType === DROPQUESTION_TYPE))
        nextQuestion = ExamService.nextQuestion(
          exam.examId,
          user.id,
          currentQuestion.questionId - 1,
          exam.clinicianAssessmentId
        );
      if (isValid && qType === CHECKLISTQUESTION_TYPE)
        nextQuestion = ExamService.nextQuestion(
          exam.examId,
          user.id,
          currentQuestion.questionId - 1,
          exam.clinicianAssessmentId
        );
    }
    if (updateQuestionService) {
      updateQuestionService
        .then(response => {
          nextQuestion
            .then(response => {
              setInvalidQuestion(false);

              if (currentQuestion.questionId === lastquestionid) setLastquestionid(lastquestionid + 1);

              if (response.questionId > lastquestionid) setLastquestionid(response.questionId);

              fillCurrentAnswer(response);

              setCurrentQuestion(response);

              setIsLoaded(true);
            })
            .catch(error => {
              toastErrorMessage(error);
            });
        })
        .catch(error => {
          toastErrorMessage(error);
        });
    } else {
      if (nextQuestion) {
        nextQuestion
          .then(response => {
            setInvalidQuestion(false);

            fillCurrentAnswer(response);

            setCurrentQuestion(response);

            if (response.questionId > lastquestionid) setLastquestionid(response.questionId);

            setIsLoaded(true);
          })
          .catch(error => {
            toastErrorMessage(error);
          });
      }
    }
  };

  const onNextClick = e => {
    e.preventDefault();

    if (currentQuestion.questionId === limits.lastQuestionId) return;

    let updateQuestionService;
    let isValid = isValidQuestion();
    const qType = questionType(currentQuestion.questionTypeDescription);
    switch (qType) {
      case RADIOQUESTION_TYPE:
      case CHECKQUESTION_TYPE:
      case DROPQUESTION_TYPE:
      case DROP_MULTIPLE_QUESTION_TYPE:
        if (isValid) {
          updateQuestionService = asyncUpdateQuestion(qType);
        } else {
          setInvalidQuestion(true);
        }
        break;
      case CHECKLISTQUESTION_TYPE:
        if (Array.isArray(isValid) && !isValid.every(answer => answer.isValid)) {
          setInvalidQuestion(isValid);
        } else if (!isValid) {
          setInvalidQuestion(true);
        } else {
          setInvalidQuestion(false);

          updateQuestionService = asyncUpdateQuestion(qType);
        }
        break;
      default:
        console.log(`Sorry, unknown question. ${qType}`);
    }
    if (isValid)
      if (updateQuestionService) {
        updateQuestionService
          .then(response => {
            ExamService.nextQuestion(exam.examId, user.id, currentQuestion.questionId + 1, exam.clinicianAssessmentId)
              .then(response => {
                setInvalidQuestion(false);

                fillCurrentAnswer(response);

                setCurrentQuestion(response);

                if (response.questionId > lastquestionid) setLastquestionid(response.questionId);

                setIsLoaded(true);
                setInComments(''); // Reiniciar las notas
              })
              .catch(error => {
                toastErrorMessage(error);
              });
          })
          .catch(error => {
            toastErrorMessage(error);
          });
      }
  };

  const onLastClick = e => {
    e.preventDefault();

    if (currentQuestion.questionId === limits.lastQuestionId) {
      setShowConfirmDialog(true);
      return;
    }

    let updateQuestionService;

    let isValid = isValidQuestion();
    const qType = questionType(currentQuestion.questionTypeDescription);
    switch (qType) {
      case RADIOQUESTION_TYPE:
      case CHECKQUESTION_TYPE:
      case DROPQUESTION_TYPE:
      case DROP_MULTIPLE_QUESTION_TYPE:
        if (isValid) {
          updateQuestionService = asyncUpdateQuestion(qType);
        } else {
          setInvalidQuestion(true);
        }
        break;
      case CHECKLISTQUESTION_TYPE:
        if (Array.isArray(isValid) && !isValid.every(answer => answer.isValid)) {
          setInvalidQuestion(isValid);
        } else if (!isValid) {
          setInvalidQuestion(true);
        } else {
          setInvalidQuestion(false);

          updateQuestionService = asyncUpdateQuestion(qType);
        }
        break;
      default:
        console.log(`Sorry, unknown question. ${qType}`);
    }

    if (updateQuestionService) {
      updateQuestionService
        .then(response => {
          let lastindexidis = 0;
          if (lastquestionid >= limits.lastQuestionId) {
            lastindexidis = limits.lastQuestionId;
          }
          ExamService.nextQuestion(exam.examId, user.id, lastindexidis, exam.clinicianAssessmentId)
            .then(response => {
              setInvalidQuestion(false);

              fillCurrentAnswer(response);

              setCurrentQuestion(response);

              if (response.questionId > lastquestionid) setLastquestionid(response.questionId);

              setIsLoaded(true);
            })
            .catch(error => {
              toastErrorMessage(error);
            });
        })
        .catch(error => {
          toastErrorMessage(error);
        });
    }
  };

  const paginatorItemClick = e => {
    e.preventDefault();

    if (currentQuestion.questionId === parseInt(e.target.dataset['questionnumber'])) return;

    let updateQuestionService, nextQuestion, qType;
    qType = questionType(currentQuestion.questionTypeDescription);

    if (currentQuestion.questionId === limits.lastQuestionId) {
      if (invalidQuestion) setInvalidQuestion(false);
      nextQuestion = ExamService.nextQuestion(
        exam.examId,
        user.id,
        parseInt(e.target.dataset['questionnumber']),
        exam.clinicianAssessmentId
      );
    } else {
      let isValid = isValidQuestion();
      switch (qType) {
        case RADIOQUESTION_TYPE:
        case CHECKQUESTION_TYPE:
        case DROPQUESTION_TYPE:
        case DROP_MULTIPLE_QUESTION_TYPE:
          if (isValid) {
            updateQuestionService = asyncUpdateQuestion(qType);
          } else {
            setInvalidQuestion(true);
          }
          break;
        case CHECKLISTQUESTION_TYPE:
          // when the value of qType is CHECKLISTQUESTION_TYPE
          //   1. If there is any answer, it is saved.
          //   2. The previous question is taken
          setInvalidQuestion(false);

          if (Array.isArray(isValid) && isValid.some(answer => answer.isValid)) {
            updateQuestionService = asyncUpdateQuestion(qType);
          } else {
            isValid = true;
          }
          break;
        default:
          console.log(`Sorry, unknown question. ${qType}`);
      }
      if (isValid && (qType === RADIOQUESTION_TYPE || qType === CHECKQUESTION_TYPE || qType === DROPQUESTION_TYPE))
        nextQuestion = ExamService.nextQuestion(
          exam.examId,
          user.id,
          parseInt(e.target.dataset['questionnumber']),
          exam.clinicianAssessmentId
        );
      if (isValid && qType === CHECKLISTQUESTION_TYPE)
        nextQuestion = ExamService.nextQuestion(
          exam.examId,
          user.id,
          parseInt(e.target.dataset['questionnumber']),
          exam.clinicianAssessmentId
        );
    }

    if (updateQuestionService) {
      updateQuestionService
        .then(response => {
          nextQuestion
            .then(response => {
              setInvalidQuestion(false);

              if (currentQuestion.questionId === lastquestionid) setLastquestionid(lastquestionid + 1);

              if (response.questionId > lastquestionid) setLastquestionid(response.questionId);

              fillCurrentAnswer(response);

              setCurrentQuestion(response);

              setIsLoaded(true);
            })
            .catch(error => {
              toastErrorMessage(error);
            });
        })
        .catch(error => {
          toastErrorMessage(error);
        });
    } else {
      if (nextQuestion)
        nextQuestion
          .then(response => {
            setInvalidQuestion(false);

            fillCurrentAnswer(response);

            setCurrentQuestion(response);

            if (response.questionId > lastquestionid) setLastquestionid(response.questionId);

            setIsLoaded(true);
          })
          .catch(error => {
            toastErrorMessage(error);
          });
    }
  };

  const isValidQuestion = () => {
    let result = false;

    if (form.answer) {
      // const questionType = currentQuestion.questionTypeDescription;
      let qType = currentQuestion.questionTypeDescription;

      if (isRadioQuestion(qType) || isDropQuestion(qType)) {
        result = form.answer !== '';
      } else if (isCheckListQuestion(qType)) {
        let properties = Object.getOwnPropertyNames(form.answer);

        result =
          currentQuestion.validAnswers.every(proposedanswer => {
            let findkey = 'proficiencyanswerId_' + proposedanswer.validAnswerId;
            let found = properties.find(property => property === findkey);
            let descriptor = Object.getOwnPropertyDescriptor(form.answer, findkey);
            return found && descriptor.value !== null && descriptor.value !== 0;
          }) &&
          currentQuestion.validAnswers.every(proposedanswer => {
            let findkey = 'frequencyanswerId_' + proposedanswer.validAnswerId;
            let found = properties.find(property => property === findkey);
            let descriptor = Object.getOwnPropertyDescriptor(form.answer, findkey);
            return found && descriptor.value !== null && descriptor.value !== 0;
          });
      } else if (isCheckQuestion(qType)) {
        let entries = Object.entries(form.answer);
        let answerQuantity = entries.reduce((count, entry) => (entry[1] ? count + 1 : count), 0);

        result = answerQuantity > 0;
        if (!result) setInvalidMessage('');
      } else if (isDropMultipleQuestion(qType)) {
        // Para DropMultipleQuestion, no requerimos selección mínima
        result = true;
      }
    }

    return result;
  };

  const getAnswer = answer => {
    if (isRadioQuestion(currentQuestion.questionTypeDescription)) {
      return answer.answerId ? [parseInt(answer.answerId)] : [parseInt(answer)];
    } else if (isCheckListQuestion(currentQuestion.questionTypeDescription)) {
      let checklistAnswers = [];
      let checklistProperties = Object.entries(answer);

      currentQuestion.validAnswers.forEach(proposedanswer => {
        let findproficiencykey = 'proficiencyanswerId_' + proposedanswer.validAnswerId;
        let findfrequencykey = 'frequencyanswerId_' + proposedanswer.validAnswerId;

        let foundproficiency = checklistProperties.find(property => property[0] === findproficiencykey);
        let foundfrequency = checklistProperties.find(property => property[0] === findfrequencykey);

        checklistAnswers.push({
          answerId: proposedanswer.validAnswerId,
          proficiency: foundproficiency ? foundproficiency[1] : 0,
          frequency: foundfrequency ? foundfrequency[1] : 0
        });
      });

      return checklistAnswers;
    } else if (isCheckQuestion(currentQuestion.questionTypeDescription)) {
      let multiAnswers = [];
      let multiProperties = Object.entries(answer);
      multiAnswers = multiProperties
        .filter(answer => answer[1])
        .map(answer => {
          return parseInt(answer[1]);
        });

      return multiAnswers;
    } else if (isDropQuestion(currentQuestion.questionTypeDescription)) {
      return [parseInt(answer)];
    } else if (isDropMultipleQuestion(currentQuestion.questionTypeDescription)) {
      let multiAnswers = [];
      if (Array.isArray(answer.answer)) {
        const answerArr = answer.answer;

        multiAnswers = answerArr.map(ans => parseInt(ans));
      } else if (typeof answer === 'object' && answer !== null) {
        let multiProperties = Object.entries(answer);

        multiAnswers = multiProperties
          .filter(property => property[1] !== null && property[1] !== undefined)
          .map(property => parseInt(property[1]));
      }

      return multiAnswers;
    } else return [];
  };

  const onPressEnterKey = e => {
    if (e.key === 'Enter') {
      onNextClick(e);
    }
  };

  const handle_OnSubmit = e => {
    e.preventDefault();

    let service;
    let isValid = isValidQuestion();
    const qType = questionType(currentQuestion.questionTypeDescription);
    if (qType === CHECKLISTQUESTION_TYPE) {
      if (Array.isArray(isValid) && !isValid.every(answer => answer.isValid)) {
        setInvalidQuestion(isValid);
      } else if (!isValid) {
        setInvalidQuestion(true);
      } else {
        setInvalidQuestion(false);
        service = ExamService.updateQuestionChecklist(
          exam.examId,
          user.id,
          currentQuestion.questionId,
          getAnswer(form.answer)
        );
      }
    } else {
      if (!isValid) {
        setInvalidQuestion(true);
      } else {
        setInvalidQuestion(false);
        service = ExamService.updateQuestion(
          exam.examId,
          user.id,
          currentQuestion.questionId,
          getAnswer(form.answer),
          exam.clinicianAssessmentId,
          inComments
        );
      }
    }
    if (service) {
      const submitSpinner = document.getElementById('submitSpinner');
      submitSpinner.style.display = 'inline-block'; // Mostrar el spinner

      const submitButton = document.getElementById('submitButton');
      submitButton.disabled = true;

      service
        .then(response => {
          ExamService.complete(exam.examId, user.id, exam.clinicianAssessmentId)
            .then(response => {
              toast.success(`Exam completed successfully.`);
              close();
            })
            .catch(error => {
              toastErrorMessage(error);
            });

          setVisible(false);
        })
        .catch(error => {
          toastErrorMessage(error);
        });
    }
  };

  const handle_OnChange = data => {
    setForm({
      ...form,
      answer: data.selectedValue
    });

    // setSelectedExtendedDescription(data.selectedExtendedDescription || '');
    setSelectedOtherDetail(data.selectedOtherDetail || '');
  };

  const handle_OnChangeChecked = e => {
    let answers = {};
    if (e.target.type === 'checkbox') {
      answers = {
        ...form.answer,
        [e.target.name]: e.target.checked ? e.target.value : ''
      };
    } else if (e.target.type === 'radio') {
      answers = {
        ...form.answer,
        [e.target.name]: e.target.value
      };
    }

    setForm({
      ...form,
      answer: answers
    });
  };

  const handle_OnChangeSelected = data => {
    let answers = {};

    if (data.selectedValues.length > 0) {
      answers = {
        ...form.answer,
        answer: data.selectedValues
      };
      setForm({
        ...form,
        answer: answers
      });
    } else {
      answers = { answerIdnull: null };
      setForm({
        answer: answers
      });
    }

    // setSelectedExtendedDescription(data.selectedExtendedDescription || '');
    setSelectedOtherDetail(data.selectedOtherDetail || '');
  };

  const close = () => {
    setVisible(false);
    setIsLoaded(false);
    setCurrentQuestion(null);
    setLastquestionid(0);
    setForm(initialForm);
    setInComments(''); // Resetear las notas
  };

  const onClose = () => {
    let doClose = true;

    if (currentQuestion) {
      let qType = questionType(currentQuestion.questionTypeDescription);

      if (currentQuestion.questionId !== limits.lastQuestionId && qType === CHECKLISTQUESTION_TYPE) {
        let isValid = isValidQuestion();
        if (Array.isArray(isValid) && isValid.some(answer => answer.isValid)) {
          doClose = false;
          let updateQuestionService = asyncUpdateQuestion(qType);
          updateQuestionService
            .then(response => {
              doClose = true;
              close();
            })
            .catch(error => {
              toastErrorMessage(error);
            });
        }
      }
    }

    if (doClose) {
      close();
    }
  };

  const qPattern = strValue => {
    if (strValue) {
      let splited = strValue.split('_');
      return parseInt(splited[2]);
    }
    return 0;
  };

  const PaginatorRender = () => {
    let questions = [];

    const maxPages = IS_MOBILE_VIEW ? 0 : 5; // Cambia a 5 páginas en una PC

    for (let question = limits.firstQuestionId; question < lastquestionid; question++) {
      questions.push(question);
    }

    const currentPageIndex = currentQuestion.questionId - limits.firstQuestionId;
    let start = 0;
    let end = 0;

    if (questions.length > maxPages) {
      if (currentPageIndex < maxPages / 2) {
        start = 0;
        end = maxPages;
      } else if (currentPageIndex >= questions.length - maxPages / 2) {
        start = questions.length - maxPages;
        end = questions.length;
      } else {
        start = currentPageIndex - Math.floor(maxPages / 2);
        end = currentPageIndex + Math.ceil(maxPages / 2);
      }
    } else {
      start = 0;
      end = questions.length;
    }

    const visibleQuestions = questions.slice(start, end);

    return visibleQuestions.map((question, index) => {
      return (
        <PaginationItem key={question}>
          <Button className="page-link" type="button" data-questionnumber={question} onClick={paginatorItemClick}>
            {question - limits.firstQuestionId + 1}
          </Button>
        </PaginationItem>
      );
    });
  };

  const backdropManager = () => {
    if (
      currentQuestion &&
      currentQuestion.questionTypeDescription &&
      isCheckListQuestion(currentQuestion.questionTypeDescription) &&
      backdrop === 'static'
    ) {
      return 'static';
    } else {
      return backdrop;
    }
  };

  const isFormExam = useCallback(() => {
    return exam && exam.examType && exam.examType.toLowerCase() === 'form';
  }, [exam]);
  
  const isGuidedExam = useCallback(() => {
    return exam && exam.examType && exam.examType.toLowerCase() === 'guided';
  }, [exam]);

  useEffect(() => {
    if (currentQuestion && limits) {
      setQuestionIndex(currentQuestion.questionId - limits.firstQuestionId + 1);
    }
  }, [currentQuestion, limits]);

  useEffect(() => {
    function close() {
      setVisible(false);
      setIsLoaded(false);
      setCurrentQuestion(null);
      setLastquestionid(0);
      setForm(initialForm);
    }

    function examStart() {
      ExamService.start(exam.examId, user.id, exam.clinicianAssessmentId)
        .then(response => {
          ExamService.nextQuestion(exam.examId, user.id, 0, exam.clinicianAssessmentId)
            .then(response => {
              fillCurrentAnswer(response);
              setCurrentQuestion(response);
              setLastquestionid(response.questionId);
              setIsLoaded(true);
            })
            .catch(error => {
              if (error.response) {
                if (error.response.data.message === 'Index: 0, Size: 0') {
                  toast.error('An error has occurred! Please contact customer service!');
                } else {
                  toast.error(error.response.data.message);
                }
              } else {
                toast.error(error.message);
              }
              close();
            });
        })
        .catch(error => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

          toast.error(`${resMessage}`);
        });
    }

    if (visible && exam && user) {
      examStart();
    }
    // eslint-disable-next-line
  }, [visible, setVisible, exam, user]);

  useEffect(() => {
    function examLimits() {
      ExamService.limits(exam.examId)
        .then(response => {
          setLimits(response.data);
        })
        .catch(error => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
  
          toast.error(`${resMessage}`);
        });
    }
  
    if (exam) {
      examLimits();
      if (isFormExam() || isGuidedExam()) {
        setIsDeselectable(true);
      }
    }
    setInvalidQuestion(false);
  }, [exam, isFormExam, isGuidedExam]); // Incluir las funciones como dependencias

  const mytoggle = () => setVisible(!visible);

  const shouldShowElement = () => {
    return isLoaded && !isFormExam() && !isGuidedExam();
  };
  

  return (
    <Modal
      isOpen={visible}
      toggle={mytoggle}
      style={{ margin: '0' }}
      contentClassName={
        currentQuestion && isCheckListQuestion(currentQuestion.questionTypeDescription)
          ? 'checklist-modal-content'
          : 'custom-modal-content'
      }
      onKeyDown={onPressEnterKey}
      backdrop={backdropManager()}
      onClosed={onClose}
    >
      <Modal isOpen={showConfirmDialog} toggle={() => setShowConfirmDialog(!showConfirmDialog)}>
        <ModalHeader>Exam</ModalHeader>
        <ModalBody>Are you sure you want to complete this exam?</ModalBody>
        <ModalFooter>
          <Button onClick={() => setShowConfirmDialog(!showConfirmDialog)}>Cancel</Button>
          <Button
            color="danger"
            onClick={e => {
              handle_OnSubmit(e);
              setShowConfirmDialog(!showConfirmDialog);
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>

      {exam && (
        <ModalHeader
          toggle={mytoggle}
          close={
            <button className="close float-none" type="button" aria-label="Close" onClick={mytoggle}>
              <span aria-hidden="true">
                <span className="fs-1 font-weight-light align-middle" style={{ fontSize: '0.7em' }}>
                  close to save
                </span>{' '}
                x
              </span>
            </button>
          }
        >
          {exam.examName}
        </ModalHeader>
      )}
      <ModalBody>
        {!isLoaded && <Loader />}
        {isLoaded && isRadioQuestion(currentQuestion.questionTypeDescription) && (
          <RadioQuestion
            question={currentQuestion.questionText}
            proposedanswers={currentQuestion.validAnswers}
            answer={
              currentQuestion.clinicianAnswersList && currentQuestion.clinicianAnswersList.length > 0
                ? currentQuestion.clinicianAnswersList[0].clinicianAnswerId
                : 0
            }
            image={currentQuestion.imageLocation}
            onChange={handle_OnChange}
            invalid={invalidQuestion}
            selectedExtendedDescription={selectedExtendedDescription}
            selectedOtherDetail={selectedOtherDetail}
            isDeselectable={isDeselectable}
          />
        )}
        {isLoaded && isCheckListQuestion(currentQuestion.questionTypeDescription) && (
          <>
            <CheckListDescription />
            <CheckListQuestion
              question={currentQuestion.questionText}
              proposedanswers={currentQuestion.validAnswers}
              answer={currentQuestion.clinicianAnswer}
              onChange={handle_OnChangeChecked}
              invalid={invalidQuestion}
              inline
            />
          </>
        )}
        {isLoaded && isCheckQuestion(currentQuestion.questionTypeDescription) && (
          <CheckQuestion
            question={currentQuestion.questionText}
            proposedanswers={currentQuestion.validAnswers}
            answer={currentQuestion.clinicianAnswersList.map(answer => answer.clinicianAnswerId)}
            onChange={handle_OnChangeChecked}
            invalid={invalidQuestion}
            invalidMessage={invalidMessage}
          />
        )}
        {isLoaded && isDropQuestion(currentQuestion.questionTypeDescription) && (
          <DropQuestion
            question={currentQuestion.questionText}
            proposedanswers={currentQuestion.validAnswers}
            answer={
              currentQuestion.clinicianAnswersList ? currentQuestion.clinicianAnswersList[0].clinicianAnswerId : 0
            }
            onChange={handle_OnChange}
            invalid={invalidQuestion}
            selectedExtendedDescription={selectedExtendedDescription}
            selectedOtherDetail={selectedOtherDetail}
          />
        )}
        {isLoaded && isDropMultipleQuestion(currentQuestion.questionTypeDescription) && (
          <DropMultipleQuestion
            question={currentQuestion.questionText}
            proposedanswers={currentQuestion.validAnswers}
            answer={
              currentQuestion.clinicianAnswersList ? currentQuestion.clinicianAnswersList[0].clinicianAnswerId : 0
            }
            onChange={handle_OnChangeSelected}
            invalid={invalidQuestion}
            selectedExtendedDescription={selectedExtendedDescription}
            selectedOtherDetail={selectedOtherDetail}
          />
        )}
        {isGuidedExam() && (
          <FormGroup className="mt-3">
            {/* <Label htmlFor="inComments">Notas Adicionales</Label> */}
            <textarea
              id="inComments"
              name="inComments"
              value={inComments}
              onChange={handleNotesChange}
              placeholder="Additional Notes"
              className="form-control"
              style={{ width: '100%', minHeight: '37px', height: '37px' }}
            />
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter className="d-flex">
        {shouldShowElement() && (
          <div className="mr-auto ">
            <PageNofM
              className="my-auto"
              n={questionIndex}
              m={limits && limits.lastQuestionId ? limits.lastQuestionId - limits.firstQuestionId + 1 : 0}
            />
          </div>
        )}
        <div>
          <Pagination aria-label="Page navigation example" size="md">
            <PaginationItem>
              {shouldShowElement() && currentQuestion.questionId > limits.firstQuestionId && (
                <Button
                  className="page-link"
                  type="button"
                  data-questionnumber={limits.firstQuestionId}
                  onClick={onFirstClick}
                >
                  {IS_MOBILE_VIEW ? '|<<' : 'First'}
                </Button>
              )}
            </PaginationItem>
            <PaginationItem>
              {shouldShowElement() && currentQuestion.questionId > limits.firstQuestionId && (
                <Button className="page-link" type="button" onClick={onPreviousClick}>
                  {IS_MOBILE_VIEW ? '<' : 'Prev'}
                </Button>
              )}
            </PaginationItem>
            {shouldShowElement() && <PaginatorRender />}
            <PaginationItem>
              {isLoaded && currentQuestion.questionId !== limits.lastQuestionId && (
                <Button className="page-link" type="button" onClick={onNextClick}>
                  {IS_MOBILE_VIEW ? '>' : 'Next'}
                </Button>
              )}
            </PaginationItem>
            <PaginationItem>
              {shouldShowElement() && currentQuestion.questionId !== limits.lastQuestionId && (
                <Button className="page-link" type="button" onClick={onLastClick}>
                  {IS_MOBILE_VIEW ? '>>|' : 'Last'}
                </Button>
              )}
            </PaginationItem>
            <PaginationItem>
              {isLoaded && currentQuestion.questionId === limits.lastQuestionId && (
                <Button id="submitButton" className="page-link" type="button" onClick={onLastClick}>
                  <Spinner
                    id="submitSpinner"
                    size="sm"
                    color="success"
                    style={{ display: 'none' }}
                    aria-hidden="true"
                    role="status"
                    className="mr-1"
                  />
                  <span>{'Submit'}</span>
                </Button>
              )}
            </PaginationItem>
          </Pagination>
        </div>
      </ModalFooter>
    </Modal>
  );
};
export default ExamDialog;
